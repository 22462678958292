/*
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */
 .userWayContainer {
  margin-top: -1px !important;
}
.userWayBackGroundBody {
  cursor: pointer;
  background: transparent !important;
  border: none;
  border-radius: 50% !important;
  padding: 3px;
  display: inline-block;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  box-sizing: content-box !important;
}
.userWayBackGroundBody:hover {
    transition: transform .4s!important;
    transform: scale(1.25);
}
@media only screen and (max-width: 819.7px) {
  .userWayBackGroundBody {
    cursor: pointer;
    background: transparent !important;
    border: none;
    border-radius: 50% !important;
    padding: 3px;
    display: inline-block;
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    max-width: 35px;
    max-height: 35px;
    box-sizing: content-box !important;
  }
}

@media only screen and (max-width: 819.7px) {
    .userWayContainer {
        margin-top: -3rem !important;
        position: absolute;
        right: 0;
    }
}

@media only screen and (min-width: 820px) and (max-width: 991.7px) {
    .userWayContainer {
        margin-top: -3rem !important;
        position: absolute;
        right: 0;
        margin-right: -0.5rem;
    }
}

@media only screen and (min-width: 992px) {
    .userWayContainer {
        margin-top: 0rem !important;
        margin-right: -2rem;
    }
}